import { createAction, props } from '@ngrx/store';
import { CO2HistoricalDataChange } from '../models/co2-historical-data-change.model';

export const loadCO2HistoricalDataChangeDetail = createAction(
  '[CO2 Historical Data Change/API] Load CO2 Historical Data Change Detail',
  props<{ historyId: number }>()
);

export const loadCO2HistoricalDataChangeDetailSuccess = createAction(
  '[CO2 Historical Data Change/API] Load CO2 Historical Data Change Detail Success',
  props<{ res: CO2HistoricalDataChange[] }>()
);

export const loadCO2HistoricalDataChangeDetailFailure = createAction(
  '[CO2 Historical Data Change/API] Load CO2 Historical Data Change Detail Failure',
  props<{ res: any }>()
);
