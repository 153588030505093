import { createAction, props } from '@ngrx/store';
import { InjuredPeople } from '../models/injured-people.model';

export const loadInjuredPeopleList = createAction(
  '[Injured People/API] Load Injured People List'
);

export const loadInjuredPeopleListSuccess = createAction(
  '[Injured People/API] Load Injured People List Success',
  props<{ res: { data: Partial<InjuredPeople>[]; totalCount: number } }>()
);

export const loadInjuredPeopleListFailure = createAction(
  '[Injured People/API] Load Injured People List Failure',
  props<{ msg: any }>()
);

export const loadInjuredPeopleDetail = createAction(
  '[Injured People/API] Load Injured People Detail',
  props<{ id: number }>()
);

export const loadInjuredPeopleDetailSuccess = createAction(
  '[Injured People/API] Load Injured People Detail Success',
  props<{ res: any }>()
);

export const loadInjuredPeopleDetailFailure = createAction(
  '[Injured People/API] Load Injured People Detail Failure',
  props<{ msg: any }>()
);

export const updateInjuredPeopleDetail = createAction(
  '[Injured People/API] Update Injured People Detail',
  props<{ para: any }>()
);

export const updateInjuredPeopleDetailSuccess = createAction(
  '[Injured People/API] Update Injured People Detail Success',
  props<{ res: any }>()
);

export const updateInjuredPeopleDetailFailure = createAction(
  '[Injured People/API] Update Injured People Detail Failure',
  props<{ msg: any }>()
);

// 导出功能
export const exportList = createAction(
  '[Injured People/API] Export Injured People List',
);

export const exportListSuccess = createAction(
  '[Injured People/API] Export Injured People List Success',
  props<{ res: any }>()
);

export const exportListFailure = createAction(
  '[Injured People/API] Export Injured People List Failure',
  props<{ msg: any }>()
);

// 结案
export const caseClose = createAction(
  '[Injured People/API] Export Injured People List',
  props<{ id: any }>()
);

export const caseCloseSuccess = createAction(
  '[Injured People/API] Close Case Success',
  props<{ res: any }>()
);

export const caseCloseFailure = createAction(
  '[Injured People/API] Close Case Failure',
  props<{ msg: any }>()
);