import { createReducer, on } from '@ngrx/store';
import {
  DashboardApiActions,
} from '../actions';

export const featureKey = 'detail';

export interface State {
  issueCount: any;
  issueCompletion: any;
  inspectionAnalysis: any;
  govInspection: any;
  documents: any;
  orgData: any;
}

export const initialState: State = {
  issueCount: null,
  issueCompletion: null,
  inspectionAnalysis: null,
  govInspection: null,
  documents: null,
  orgData: null,
};

export const reducer = createReducer(
  initialState,
  on(
    DashboardApiActions.loadIssueCountSuccess,
    (state, { res }) => ({
      ...state,
      issueCount: res
    })
  ),
  on(
    DashboardApiActions.loadIssueCompletionSuccess,
    (state, { res }) => ({
      ...state,
      issueCompletion: res
    })
  ),
  on(
    DashboardApiActions.loadInspectionAnalysisSuccess,
    (state, { res }) => ({
      ...state,
      inspectionAnalysis: res
    })
  ),
  on(
    DashboardApiActions.loadGovInspectionSuccess,
    (state, { res }) => ({
      ...state,
      govInspection: res
    })
  ),
  on(
    DashboardApiActions.loadDocumentsSuccess,
    (state, { res }) => ({
      ...state,
      documents: res
    })
  ),
  on(
    DashboardApiActions.loadOrgDataSuccess,
    (state, { res }) => ({
      ...state,
      orgData: res
    })
  ),
);
