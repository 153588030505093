import {
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';

import * as fromRoot from '@_core/reducers';
import * as fromDashboard from '../dashboard/reducers';

export const featureKey = 'report';

export interface SystemSetting {
  [fromDashboard.featureKey]: fromDashboard.State;
}

export interface State extends fromRoot.State {
  [featureKey]: SystemSetting;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromDashboard.featureKey]: fromDashboard.reducers,
  })(state, action);
}

export const getNotification = createFeatureSelector<State, SystemSetting>(
  featureKey
);
