import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
  combineReducers,
  Action
} from '@ngrx/store';
import * as detail from './dashboard.reducer';

export const featureKey = 'dashboard';

export interface DashboardState {
  [detail.featureKey]: detail.State;
}

export interface State {
  [featureKey]: DashboardState;
}

export function reducers(state: DashboardState | undefined, action: Action) {
  return combineReducers({
    [detail.featureKey]: detail.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('statistics');
export const getDashboardState = createSelector(
  getParentState,
  state => state[featureKey]
);

// dashboard
// ----------------------------------------------------------------------------
export const getIssueCount = createSelector(
  getDashboardState,
  state => state.detail.issueCount
)

export const getIssueCompletion = createSelector(
  getDashboardState,
  state => state.detail.issueCompletion
)

export const getInspectionAnalysis = createSelector(
  getDashboardState,
  state => state.detail.inspectionAnalysis
)

export const getGovInspection = createSelector(
  getDashboardState,
  state => state.detail.govInspection
)

export const getDocuments = createSelector(
  getDashboardState,
  state => state.detail.documents
)

export const getOrgData = createSelector(
  getDashboardState,
  state => state.detail.orgData
)
