import { props, createAction } from '@ngrx/store';
import { DashboardPageQuery } from '../models/dashboard-query.model';

// Emission Summary
export const loadEmissionSummary = createAction(
  '[Carbon Emission Dashboard/API] Load Emission Summary',
  props<{ para: DashboardPageQuery }>()
);

export const loadEmissionSummarySuccess = createAction(
  '[Carbon Emission Dashboard/API] Load Emission Summary Success',
  props<{ res: any }>()
);

export const loadEmissionSummaryFailure = createAction(
  '[Carbon Emission Dashboard/API] Load Emission Summary Failure',
  props<{ msg: any }>()
);

// Emission Range Analysis
export const loadEmissionRangeAnalysis = createAction(
  '[Carbon Emission Dashboard/API] Load Emission Range Analysis',
  props<{ para: DashboardPageQuery }>()
);

export const loadEmissionRangeAnalysisSuccess = createAction(
  '[Carbon Emission Dashboard/API] Load Emission Range Analysis Success',
  props<{ res: any }>()
);

export const loadEmissionRangeAnalysisFailure = createAction(
  '[Carbon Emission Dashboard/API] Load Emission Range Analysis Failure',
  props<{ msg: any }>()
);

// GHG Emission Percentage
export const loadGHGEmissionPercentage = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Percentage',
  props<{ para: DashboardPageQuery }>()
);

export const loadGHGEmissionPercentageSuccess = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Percentage Success',
  props<{ res: any }>()
);

export const loadGHGEmissionPercentageFailure = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Percentage Failure',
  props<{ msg: any }>()
);

// GHG Emission Trend
export const loadGHGEmissionTrend = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Trend',
  props<{ para: DashboardPageQuery }>()
);

export const loadGHGEmissionTrendSuccess = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Trend Success',
  props<{ res: any }>()
);

export const loadGHGEmissionTrendFailure = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Trend Failure',
  props<{ msg: any }>()
);

// GHG Source Consumption
export const loadGHGSourceConsumption = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Source Consumption',
  props<{ para: DashboardPageQuery }>()
);

export const loadGHGSourceConsumptionSuccess = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Source Consumption Success',
  props<{ res: any }>()
);

export const loadGHGSourceConsumptionFailure = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Source Consumption Failure',
  props<{ msg: any }>()
);

// Reduction Summary
export const loadReductionSummary = createAction(
  '[Carbon Emission Dashboard/API] Load Reduction Summary',
  props<{ para: DashboardPageQuery }>()
);

export const loadReductionSummarySuccess = createAction(
  '[Carbon Emission Dashboard/API] Load Reduction Summary Success',
  props<{ res: any }>()
);

export const loadReductionSummaryFailure = createAction(
  '[Carbon Emission Dashboard/API] Load Reduction Summary Failure',
  props<{ msg: any }>()
);

// GHG Emission Trend By Org
export const loadGHGEmissionTrendByOrg = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Trend By Org',
  props<{ para: DashboardPageQuery }>()
);

export const loadGHGEmissionTrendByOrgSuccess = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Trend By Org Success',
  props<{ res: any }>()
);

export const loadGHGEmissionTrendByOrgFailure = createAction(
  '[Carbon Emission Dashboard/API] Load GHG Emission Trend By Org Failure',
  props<{ msg: any }>()
);