import { createReducer, on } from '@ngrx/store';
import { BasicInfoComponent } from 'app/ehs/dashboard/_shared/components/basic-info/basic-info.component';
import {
  AdHocInspectionApiActions,
  AdHocInspectionEditPageActions,
} from '../actions';
import { AdHocInspection } from '../models/ad-hoc-inspection.model';

export const featureKey = 'editing';

export type State = AdHocInspection | null;

export const initialState: State = {
  basicInfo: null,
  inspectionFormList: [],
  findings: []
};

export const reducer = createReducer(
  initialState,
  on(
    AdHocInspectionEditPageActions.updateBasicInfo,
    (state, { basicInfo }) => ({ ...state, basicInfo })
  ),
  on(
    AdHocInspectionEditPageActions.updateInspectionFormList,
    (state, { inspectionFormList }) => ({ ...state, inspectionFormList })
  ),
  on(
    AdHocInspectionEditPageActions.updateFindings,
    (state, { findings }) => ({ ...state, findings })
  ),
  on(
    AdHocInspectionApiActions.saveAsDraftSuccess,
    (state, { inspectionId }) => {
      state.basicInfo.id = inspectionId;
      return state;
    }
  ),
  on(
    AdHocInspectionApiActions.getDraftDetailSuccess,
    (state, { res }) => ({
      ...state,
      inspectionFormList: res.inspectionFormList,
      basicInfo: { id: res.id, org: res.org, inspectionType: res.inspectionType, review: res.review },
      findings: res.findings
    })
  ),
  on(
    // 清除
    AdHocInspectionEditPageActions.leave,
    (state) => ({
      ...initialState
    })
  ),
);
