import { createReducer, on } from '@ngrx/store';
import { CO2HistoricalDataChangePageActions } from '../actions';

export const featureKey = 'pending';

export type State = boolean;

export const initialState: State = false;

export const reducer = createReducer(
  initialState,
  on(
    // set isPending to true
    CO2HistoricalDataChangePageActions.setEditPageIsPendingToTrue,
    state => (true)
  ),
  on(
    CO2HistoricalDataChangePageActions.setEditPageIsPendingToFalse,
    state => false
  ),
);

