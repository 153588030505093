import { props, createAction } from '@ngrx/store';
// import { IssueRecord } from '../../_shared/models/issue-record.model';
// import { InspectionCorrectiveActionTasks } from '../models/issue-inspection-corrective-action-tasks.model';
// import { InspectionTasks } from '../models/issue-inspection-tasks.model';


// 获得列表
export const loadGovPenaltyList = createAction(
  '[Gov Penalty/API] Load Gov Penalty List'
);

export const loadGovPenaltyListSuccess = createAction(
  '[Gov Penalty/API] Load Gov Penalty List Success',
  props<{ res: { data: any[]; totalCount: number } }>()
);

export const loadGovPenaltyListFailure = createAction(
  '[Gov Penalty/API] Load Gov Penalty List Failure',
  props<{ res: any }>()
);

// 新增处罚
export const createGovPenalty = createAction(
  '[Gov Penalty/API] Create Gov Penalty',
  props<{ penalty: any }>()
);

export const createGovPenaltySuccess = createAction(
  '[Gov Penalty/API] Create Gov Penalty Success',
  props<{ res: any }>()
);

export const createGovPenaltyFailure = createAction(
  '[Gov Penalty/API] Create Gov Penalty Failure',
  props<{ msg: any }>()
);

// load处罚
export const loadGovPenalty = createAction(
  '[Gov Penalty/API] Load Gov Penalty',
  props<{ id: number }>()
);

export const loadGovPenaltySuccess = createAction(
  '[Gov Penalty/API] Load Gov Penalty Success',
  props<{ res: any }>()
);

export const loadGovPenaltyFailure = createAction(
  '[Gov Penalty/API] Load Gov Penalty Failure',
  props<{ msg: any }>()
);

// 更新处罚
export const updateGovPenalty = createAction(
  '[Gov Penalty/API] Update Gov Penalty',
  props<{ penalty: any }>()
);

export const updateGovPenaltySuccess = createAction(
  '[Gov Penalty/API] Update Gov Penalty Success',
  props<{ res: any }>()
);

export const updateGovPenaltyFailure = createAction(
  '[Gov Penalty/API] Update Gov Penalty Failure',
  props<{ msg: any }>()
);

