import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
  combineReducers,
  Action
} from '@ngrx/store';
import * as detail from './dashboard.reducer';

export const featureKey = 'dashboard';

export interface DashboardState {
  [detail.featureKey]: detail.State;
}

export interface State {
  [featureKey]: DashboardState;
}

export function reducers(state: DashboardState | undefined, action: Action) {
  return combineReducers({
    [detail.featureKey]: detail.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('report');
export const getDashboardState = createSelector(
  getParentState,
  state => state[featureKey]
);

// dashboard
// ----------------------------------------------------------------------------
export const getEmissionSummary = createSelector(
  getDashboardState,
  state => state.detail.emissionSummary
)

export const getEmissionRangeAnalysis = createSelector(
  getDashboardState,
  state => state.detail.emissionRangeAnalysis
)

export const getGHGEmissionPercentage = createSelector(
  getDashboardState,
  state => state.detail.ghgEmissionPercentage
)

export const getGHGEmissionTrend = createSelector(
  getDashboardState,
  state => state.detail.ghgEmissionTrend
)

export const getGHGSourceConsumption = createSelector(
  getDashboardState,
  state => state.detail.ghgSourceConsumption
)

export const getReductionSummary = createSelector(
  getDashboardState,
  state => state.detail.reductionSummary
)

export const getGHGEmissionTrendByOrg = createSelector(
  getDashboardState,
  state => state.detail.ghgEmissionTrendByOrg
)