import { createReducer, on } from '@ngrx/store';
import { GovPenaltyApiActions, GovPenaltyEditActions } from '../actions';

export const featureKey = 'editing';

export type State = any | null;

export const initialState: State = {
  detail: null,
  isPending: null
};

export const reducer = createReducer(
  initialState,
  on(
    GovPenaltyApiActions.createGovPenalty,
    GovPenaltyApiActions.updateGovPenalty,
    (state) => {
      return { ...state, isPending: true };
    }
  ),
  on(
    GovPenaltyApiActions.createGovPenaltySuccess,
    GovPenaltyApiActions.createGovPenaltyFailure,
    GovPenaltyApiActions.updateGovPenaltySuccess,
    GovPenaltyApiActions.updateGovPenaltyFailure,
    (state) => {
      return { ...state, isPending: false };
    }
  ),
  on(
    GovPenaltyApiActions.loadGovPenaltySuccess,
    (state, { res }) => ({ ...state, detail: res })
  ),
);
