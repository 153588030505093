import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { GovPenaltyApiActions, GovPenaltyEditActions } from '../actions';

// Models
import { GovPenaltyListQuery } from '../models/gov-penalty-list-query.model';

export const featureKey = 'list';

export interface State extends EntityState<any> {
  query: GovPenaltyListQuery;
  msg: string | null;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (issueRecord: any) => issueRecord.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    code: null,
    organization: null,
    govPenaltyDeptLevel: null,
    govPenaltyDept: null,
    penaltyDateFrom: null,
    penaltyDateTo: null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(
    GovPenaltyApiActions.loadGovPenaltyList,
    (state) => {
      return { ...state, isLoading: true };
    }),
  on(
    GovPenaltyApiActions.loadGovPenaltyListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount, isLoading: false };
      return adapter.addAll(res.data, newState);
    }),
  on(
    GovPenaltyApiActions.loadGovPenaltyListFailure,
    (state, { res }) => ({ ...state, res, isLoading: false })
  ),
  on(GovPenaltyEditActions.applyFilter, (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  // needs to change
  on(GovPenaltyEditActions.resetFilter, (state) => ({
    ...state,
    query: initialState.query
  })),
  on(GovPenaltyEditActions.changeSort, (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    }
  })),
  on(GovPenaltyEditActions.changeOrderBies, (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    }
  })),
  on(GovPenaltyEditActions.changeCurrentPage, (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    }
  })),
  on(GovPenaltyEditActions.changePageSize, (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    }
  })),
);
