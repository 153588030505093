import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
  combineReducers,
  Action
} from '@ngrx/store';
// import { Issue } from '../../_shared/models';
import * as fromEditing from './gov-inspection-reporting-edit.reducer';
import * as fromPending from './pending.reducer';

export const featureKey = 'govInspectionReporting';

export interface GovInspectionReportingState {
  [fromEditing.featureKey]: fromEditing.State;
  [fromPending.featureKey]: fromPending.State;
}

export interface State {
  [featureKey]: GovInspectionReportingState;
}

export function reducers(state: GovInspectionReportingState | undefined, action: Action) {
  return combineReducers({
    [fromEditing.featureKey]: fromEditing.reducer,
    [fromPending.featureKey]: fromPending.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('inspection');
export const getGovInspectionReportingState = createSelector(
  getParentState,
  state => state[featureKey]
);

// Pending
// ----------------------------------------------------------------------------

export const getIsPending = createSelector(
  getGovInspectionReportingState,
  state => state.pending
);

// Issue Reporting
// ----------------------------------------------------------------------------

export const getEditingGovInspectionReporting = createSelector(
  getGovInspectionReportingState,
  state => state.edit
);

export const getBasicInfo = createSelector(
  getEditingGovInspectionReporting,
  state => state.basicInfo
);

export const getIssues = createSelector(
  getEditingGovInspectionReporting,
  (state) => [...state.findings]
);

export const getIssueById = createSelector(
  getIssues,
  (issues, { issueId }) => issues.find(issue => issue.pseudoId === issueId)
);

export const getCorrectiveActionById = createSelector(
  getIssues,
  (issues, { issueId, corrActId }) => {
    const foundIssue: any = issues.find(issue => issue.pseudoId === issueId);
    if (!foundIssue) {
      return null;
    } else {
      return foundIssue.investigations.find(corrAct => corrAct.pseudoId === corrActId);
    }
  }
);
