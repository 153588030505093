import { createReducer, on } from '@ngrx/store';
import { SpaceApiActions } from '../actions';
import { Space } from '../models/space.model';

export const featureKey = 'spaceDetail';

export interface State {
  isPending: boolean;
  data: Space | null;
}

export const initialState: State = {
  isPending: false,
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    // 讀取
    SpaceApiActions.loadDetailInfoSuccess,
    (state, { data }) => ({
      ...state,
      data
    })
  ),
  on(
    SpaceApiActions.deleteSpacesSuccess,
    (state) => ({ ...state, ...initialState })
  ),
  on(
    SpaceApiActions.addSpace,
    SpaceApiActions.deleteSpaces,
    SpaceApiActions.updateDetail,
    SpaceApiActions.loadContactsInfo,
    SpaceApiActions.updateContacts,
    (state) => ({ ...state, isPending: true })
  ),
  on(
    SpaceApiActions.addSpaceSuccess,
    SpaceApiActions.addSpaceFailure,
    SpaceApiActions.deleteSpacesSuccess,
    SpaceApiActions.deleteSpacesFailure,
    SpaceApiActions.updateDetailSuccess,
    SpaceApiActions.updateDetailFailure,
    SpaceApiActions.loadContactsInfoSuccess,
    SpaceApiActions.loadContactsInfoFailure,
    SpaceApiActions.updateContactsSuccess,
    SpaceApiActions.updateContactsFailure,
    (state) => ({ ...state, isPending: false })
  ),
);
