import { props, createAction } from '@ngrx/store';

// issue count
export const loadIssueCount = createAction(
  '[Statistic Dashboard/API] Load Issue Count',
  props<{ para: any}>()
);

export const loadIssueCountSuccess = createAction(
  '[Statistic Dashboard/API] Load Issue Count Success',
  props<{ res: any }>()
);

export const loadIssueCountFailure = createAction(
  '[Statistic Dashboard/API] Load Issue Count Failure',
  props<{ msg: any }>()
);

// issue completion
export const loadIssueCompletion = createAction(
  '[Statistic Dashboard/API] Load Issue Completion',
  props<{ para: any}>()
);

export const loadIssueCompletionSuccess = createAction(
  '[Statistic Dashboard/API] Load Issue Completion Success',
  props<{ res: any }>()
);

export const loadIssueCompletionFailure = createAction(
  '[Statistic Dashboard/API] Load Issue Completion Failure',
  props<{ msg: any }>()
);

// inspection analysis
export const loadInspectionAnalysis = createAction(
  '[Statistic Dashboard/API] Load Inspection Analysis',
  props<{ para: any, selectByMonth: boolean }>()
);

export const loadInspectionAnalysisSuccess = createAction(
  '[Statistic Dashboard/API] Load Inspection Analysis Success',
  props<{ res: any }>()
);

export const loadInspectionAnalysisFailure = createAction(
  '[Statistic Dashboard/API] Load Inspection Analysis Failure',
  props<{ msg: any }>()
);

// gov inspection
export const loadGovInspection = createAction(
  '[Statistic Dashboard/API] Load Gov Inspection',
  props<{ para: any}>()
);

export const loadGovInspectionSuccess = createAction(
  '[Statistic Dashboard/API] Load Gov Inspection Success',
  props<{ res: any }>()
);

export const loadGovInspectionFailure = createAction(
  '[Statistic Dashboard/API] Load Gov Inspection Failure',
  props<{ msg: any }>()
);

// documents
export const loadDocuments = createAction(
  '[Statistic Dashboard/API] Load Documents',
  props<{ para: any}>()
);

export const loadDocumentsSuccess = createAction(
  '[Statistic Dashboard/API] Load Documents Success',
  props<{ res: any }>()
);

export const loadDocumentsFailure = createAction(
  '[Statistic Dashboard/API] Load Documents Failure',
  props<{ msg: any }>()
);

// org data
export const loadOrgData = createAction(
  '[Statistic Dashboard/API] Load Org Data',
  props<{ para: any, selectByMonth: boolean}>()
);

export const loadOrgDataSuccess = createAction(
  '[Statistic Dashboard/API] Load Org Data Success',
  props<{ res: any }>()
);

export const loadOrgDataFailure = createAction(
  '[Statistic Dashboard/API] Load Org Data Failure',
  props<{ msg: any }>()
);