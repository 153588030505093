import { props, createAction } from '@ngrx/store';
import { Doc } from '../models/doc-mgnt-edit.model';

// 讀取列表
export const loadDocList = createAction(
  '[Doc List/API] Load Doc List'
);

export const loadDocListSuccess = createAction(
  '[Doc List/API] Load Doc List Success',
  props<{ res: { data: Partial<Doc>[]; totalCount: number } }>()
);

export const loadDocListFailure = createAction(
  '[Doc List/API] Load Doc List Failure',
  props<{ res: any }>()
);

// 导出列表
export const exportDocList = createAction(
  '[Doc List/API] Export Doc List'
);

export const exportDocListSuccess = createAction(
  '[Doc List/API] Export Doc List Success',
  props<{ res: any }>()
);

export const exportDocListFailure = createAction(
  '[Doc List/API] Export Doc List Failure',
  props<{ res: any }>()
);