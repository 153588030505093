import { combineReducers, Action, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSpace from './space-detail.reducer';
import * as fromSpaceTree from './space-tree.reducer';

export const featureKey = 'Space';

export interface SpaceState {
  [fromSpace.featureKey]: fromSpace.State;
  [fromSpaceTree.featureKey]: fromSpaceTree.State;
}

export interface State {
  [featureKey]: SpaceState;
}

export function reducers(state: SpaceState | undefined, action: Action) {
  return combineReducers({
    [fromSpace.featureKey]: fromSpace.reducer,
    [fromSpaceTree.featureKey]: fromSpaceTree.reducer,
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('system-setting');
export const getSpaceState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

export const getSpace = createSelector(
  getSpaceState,
  state => state.spaceDetail.data
);

export const getIsDetailPending = createSelector(
  getSpaceState,
  state => state.spaceDetail.isPending
);

export const getSpaceTree = createSelector(
  getSpaceState,
  state => state.spaceTree.data
);
