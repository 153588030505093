import { createAction, props } from '@ngrx/store';
import { Satisfaction } from '../models/satisfaction.model';

// 获取满意度列表
export const loadSatisfactionList = createAction(
  '[Doctor Satisfaction/API] Load Satisfaction List'
);

export const loadSatisfactionListSuccess = createAction(
  '[Doctor Satisfaction/API] Load Satisfaction List Success',
  props<{ res: { data: Partial<Satisfaction>[]; totalCount: number } }>()
);

export const loadSatisfactionListFailure = createAction(
  '[Doctor Satisfaction/API] Load Satisfaction List Failure',
  props<{ msg: any }>()
);

// 获得检查详情
export const getSpaceInspection = createAction(
  '[Doctor Satisfaction/API] Get Satisfaction',
  props<{ id: any }>()
);

export const getSpaceInspectionSuccess = createAction(
  '[Doctor Satisfaction/API] Get Satisfaction Success',
  props<{ res: any }>()
);

export const getSpaceInspectionFailure = createAction(
  '[Doctor Satisfaction/API] Get Satisfaction Failure',
  props<{ msg: any }>()
);

// 获得检查详情表格
export const getSpaceInspectionTable = createAction(
  '[Doctor Satisfaction/API] Get Satisfaction Table',
  props<{ id: any }>()
);

export const getSpaceInspectionTableSuccess = createAction(
  '[Doctor Satisfaction/API] Get Satisfaction Table Success',
  props<{ res: any }>()
);

export const getSpaceInspectionTableFailure = createAction(
  '[Doctor Satisfaction/API] Get Satisfaction Table Failure',
  props<{ msg: any }>()
);
