import { createAction, props } from '@ngrx/store';
import { Space } from '../models/space.model';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';

// 获取组织架构信息
export const loadDetailInfo = createAction(
    '[Space/API] Load Space Detail Info',
    props<{ id: number }>()
);

export const loadDetailInfoSuccess = createAction(
    '[Space/API] Load Space Detail Info Success',
    props<{ data: any }>()
);

export const loadDetailInfoFailure = createAction(
    '[Space/API] Load Space Incident Detail Info Failure',
    props<{ msg: string }>()
);

// 新增组织架构
export const addSpace = createAction(
    '[Space/API] Add Space Detail',
    props<{ detail: Partial<Space> }>()
);

export const addSpaceSuccess = createAction(
    '[Space/API] Add Space Detail Success',
    props<{ res: any, id: number }>()
);

export const addSpaceFailure = createAction(
    '[Space/API] Add Space Detail Failure',
    props<{ msg: string }>()
);

// 更新组织架构
export const updateDetail = createAction(
    '[Space/API] Update Space Detail',
    props<{ detail: Partial<Space> }>()
);

export const updateDetailSuccess = createAction(
    '[Space/API] Update Space Detail Success',
    props<{ res: any }>()
);

export const updateDetailFailure = createAction(
    '[Space/API] Update Space Detail Failure',
    props<{ msg: string }>()
);

export const deleteSpaces = createAction(
    '[Space/API] Delete Space Detail',
    props<{ ids: number[] }>()
);

export const deleteSpacesSuccess = createAction(
    '[Space/API] Delete Space Detail Success',
    props<{ res: any }>()
);

export const deleteSpacesFailure = createAction(
    '[Space/API] Delete Space Detail Failure',
    props<{ msg: string }>()
);

// 获取联络人信息
export const loadContactsInfo = createAction(
    '[Space/API] Load Space Contacts Info',
    props<{ id: number }>()
);

export const loadContactsInfoSuccess = createAction(
    '[Space/API] Load Space Contacts Info Success',
    props<{ data: any[] }>()
);

export const loadContactsInfoFailure = createAction(
    '[Space/API] Load Space Contacts Info Failure',
    props<{ msg: string }>()
);

export const addContact = createAction(
    '[Space/API] Add Space Contact',
    props<{ orgId: number, data: any }>()
);

export const addContactSuccess = createAction(
    '[Space/API] Add Space Contact Success',
    props<{ res: any }>()
);

export const addContactFailure = createAction(
    '[Space/API] Add Space Contact Failure',
    props<{ msg: string }>()
);

export const editContact = createAction(
    '[Space/API] Edit Space Contact',
    props<{ orgId: number, data: any }>()
);

export const editContactSuccess = createAction(
    '[Space/API] Edit Space Contact Success',
    props<{ res: any }>()
);

export const editContactFailure = createAction(
    '[Space/API] Edit Space Contact Failure',
    props<{ msg: string }>()
);

export const deleteContact = createAction(
    '[Space/API] Delete Space Contact',
    props<{ orgId: number, contactId: number }>()
);

export const deleteContactSuccess = createAction(
    '[Space/API] Delete Space Contact Success',
    props<{ res: any }>()
);

export const deleteContactFailure = createAction(
    '[Space/API] Delete Space Contact Failure',
    props<{ msg: string }>()
);

export const updateContacts = createAction(
    '[Space/API] Update Space Contacts Info',
    props<{ orgId: number }>()
);

export const updateContactsSuccess = createAction(
    '[Space/API] Update Space Contacts Info Success',
    props<{ res: any }>()
);

export const updateContactsFailure = createAction(
    '[Space/API] Update Space Contacts Info Failure',
    props<{ msg: string }>()
);

export const exportCsv = createAction(
    '[Space/API] Export CSV File',
    props<{ query: QueryUtilsModel }>()
);

export const exportCsvSuccess = createAction(
    '[Space/API] Export CSV File Success',
    props<{ res: any }>()
);

export const exportCsvFailure = createAction(
    '[Space/API] Export CSV File Failure',
    props<{ msg: string }>()
);

// 获取组织架构树
export const loadSpaceTreeInfo = createAction(
    '[Space/API] Load Space Tree Info'
);

export const loadSpaceTreeInfoSuccess = createAction(
    '[Space/API] Load Space Tree Info Success',
    props<{ data: any }>()
);

export const loadSpaceTreeInfoFailure = createAction(
    '[Space/API] Load Space Tree Info Failure',
    props<{ msg: string }>()
);

// 同步空间信息
export const syncSpace = createAction(
  '[Space/API] Synchronize Space Info',
  props<{ orgIds: string }>()
);

export const syncSpaceSuccess = createAction(
  '[Space/API] Synchronize Space Info Success',
  props<{ res: any }>()
);

export const syncSpaceFailure = createAction(
  '[Space/API] Synchronize Space Info Failure',
  props<{ msg: string }>()
);

// 获取项目某服务类型下的空间
export const getSpaceChildren = createAction(
  '[Space/API] Get Space Children',
  props<{ id: string, orgId: string }>()
);

export const getSpaceChildrenSuccess = createAction(
  '[Space/API] Get Space Children Success',
  props<{ res: any }>()
);

export const getSpaceChildrenFailure = createAction(
  '[Space/API] Get Space Children Failure',
  props<{ msg: string }>()
);

// 设置子空间的管理人员
export const setChildSpaceEmployee = createAction(
  '[Space/API] Set Child Space Employee',
  props<{ para: Partial<Space> }>()
);

export const setChildSpaceEmployeeSuccess = createAction(
  '[Space/API] Set Child Space Employee Success',
  props<{ res: any }>()
);

export const setChildSpaceEmployeeFailure = createAction(
  '[Space/API] Set Child Space Employee Failure',
  props<{ msg: string }>()
);
