import {
  createFeatureSelector,
  createSelector,
  Action,
  combineReducers
} from '@ngrx/store';

import * as fromRoot from '@_core/reducers';
import * as fromCO2DataFilling from '../co2-data-filling/reducers';
import * as fromCO2DataFillingRecord from '../co2-data-filling-record/reducers';
import * as fromCO2HistoricalDataChange from '../co2-historical-data-change/reducers';

export const featureKey = 'dataFilling';

export interface DataFillingState {
  [fromCO2DataFilling.featureKey]: fromCO2DataFilling.State,
  [fromCO2DataFillingRecord.featureKey]: fromCO2DataFillingRecord.State,
  [fromCO2HistoricalDataChange.featureKey]: fromCO2HistoricalDataChange.State,
}

export interface State extends fromRoot.State {
  [featureKey]: DataFillingState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromCO2DataFilling.featureKey]: fromCO2DataFilling.reducers,
    [fromCO2DataFillingRecord.featureKey]: fromCO2DataFillingRecord.reducers,
    [fromCO2HistoricalDataChange.featureKey]: fromCO2HistoricalDataChange.reducers,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('carbonEmission');
export const getDataFillingState = createSelector(
  getParentState,
  state => state[featureKey]
);
