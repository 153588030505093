import { createReducer, on } from '@ngrx/store';
import { RoleManagementPageActions as PageActions, RoleManagementApiActions as ApiActions } from '../actions';
import { RoleManagementListFilter } from '../models';

export const featureKey = 'list';

export interface State {
  isLoading: boolean;
  query: RoleManagementListFilter;
  roleList: any[];
  totalCount: number | null;
  currentPage: number;
  pageSize: number;
  sort: { selector: string; desc: boolean; }[];
}

export const initialState: State = {
  isLoading: false,
  query: {
    code: null,  // 角色ID
    name: null,  // 角色名称
    level: null, // 角色级别
  },
  roleList: [],
  totalCount: null,
  currentPage: 1,
  pageSize: 10,
  sort: [],
};

export const reducer = createReducer(
  initialState,
  on(ApiActions.loadRoleList, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ApiActions.loadRoleListSuccess, (state, { res }) => ({
    ...state,
    isLoading: false,
    roleList: res.data,
    totalCount: res.totalCount,
  })),
  on(ApiActions.loadRoleListFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(PageActions.applyFilter, (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(PageActions.resetFilter, (state) => {
    return {
      ...state,
      query: initialState.query
    };
  }),
  on(
    PageActions.changeCurrentPage,
    (state, { currentPage }) => ({ ...state, currentPage })
  ),
  on(
    PageActions.changePageSize,
    (state, { pageSize }) => ({ ...state, pageSize })
  ),
  on(
    PageActions.changeSort,
    (state, { sort }) => ({ ...state, sort })
  ),
);
