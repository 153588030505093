import { createReducer, on } from '@ngrx/store';
import {
  CO2HistoricalDataChangeApiActions,
  CO2HistoricalDataChangePageActions,
} from '../actions';
import { CO2HistoricalDataChange } from '../models/co2-historical-data-change.model';

export const featureKey = 'editing';

export type State = CO2HistoricalDataChange | null;

export const initialState: State = {
  id: null,
  seq: null,
  name: null,
  beforeChange: null,
  afterChange: null
};

export const reducer = createReducer(
  initialState,
  on(
    // 清除
    CO2HistoricalDataChangePageActions.leave,
    (state) => ({
      ...initialState
    })
  ),
);
