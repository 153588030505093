import {
  createSelector,
  createFeatureSelector,
  combineReducers,
  Action
} from '@ngrx/store';
import * as fromList from './gov-penalty-list.reducer';
import * as fromEditing from './gov-penalty-edit.reducer';

export const featureKey = 'gov-penalty';

export interface GovPenaltyState {
  [fromList.featureKey]: fromList.State;
  [fromEditing.featureKey]: fromEditing.State;
}

export interface State {
  [featureKey]: GovPenaltyState;
}

export function reducers(state: GovPenaltyState | undefined, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEditing.featureKey]: fromEditing.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('inspection');
export const getGovPenaltyState = createSelector(
  getParentState,
  state => state[featureKey]
);


// list
// ----------------------------------------------------------------------------

export const getGovPenaltyEntitiesState = createSelector(
  getGovPenaltyState,
  state => state.list
);

export const {
  selectIds: getGovPenaltyIds,
  selectEntities: getGovPenaltyEntities,
  selectAll: getAllGovPenalty,
  selectTotal: getTotalGovPenalty
} = fromList.adapter.getSelectors(getGovPenaltyEntitiesState);

export const getGovPenaltyList = createSelector(
  getAllGovPenalty,
  getGovPenaltyEntitiesState,
  (allGovPenaltyState, govPenaltyEntitiesState) => {
    const list = {
      data: allGovPenaltyState,
      totalCount: govPenaltyEntitiesState.totalCount
    };
    return list;
  },
);

export const getGovPenaltyListQuery = createSelector(
  getGovPenaltyEntitiesState,
  state => state.query
);

export const getGovPenaltyListIsLoading = createSelector(
  getGovPenaltyState,
  state => state.list.isLoading
);

// govPenalty
// ----------------------------------------------------------------------------

export const getEditingGovPenalty = createSelector(
  getGovPenaltyState,
  state => state.editing
);

export const getEditingGovPenaltyDetail = createSelector(
  getEditingGovPenalty,
  state => state.detail
);

export const getEditingGovPenaltyDetailIsPending = createSelector(
  getEditingGovPenalty,
  state => state.isPending
);
