import { createReducer, on } from '@ngrx/store';
import {
  SatisfactionApiActions,
  SatisfactionPageActions,
} from '../actions';

export const featureKey = 'satisfactionDetail';

export interface State {
  detailInfo: any;
  tableInfo: any;
};

export const initialState: State = {
  detailInfo: null,
  tableInfo: null,
};

export const reducer = createReducer(
  initialState,
  on(
    SatisfactionApiActions.getSpaceInspectionSuccess,
    (state, { res }) => ({
      ...state,
      detailInfo: res
    })
  ),
  on(
    SatisfactionApiActions.getSpaceInspectionTableSuccess,
    (state, { res }) => ({
      ...state,
      tableInfo: res
    })
  )
);
