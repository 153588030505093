import {
  createFeatureSelector,
  createSelector,
  Action,
  combineReducers
} from '@ngrx/store';

import * as fromRoot from '@_core/reducers';
import * as fromGHGChecklist from '../ghg-checklist/reducers';
import * as fromGHGSource from '../ghg-source/reducers';
import * as fromGHGCoefficient from '../ghg-coefficient/reducers';
import * as fromESGIndicators from '../esg-indicators/reducers';
import * as fromAPIManagement from '../api-management/reducers';

export const featureKey = 'basicConfiguration';

export interface BasicConfigurationState {
  [fromGHGChecklist.featureKey]: fromGHGChecklist.State,
  [fromGHGSource.featureKey]: fromGHGSource.State,
  [fromGHGCoefficient.featureKey]: fromGHGCoefficient.State,
  [fromESGIndicators.featureKey]: fromESGIndicators.State,
  [fromAPIManagement.featureKey]: fromAPIManagement.State,
}

export interface State extends fromRoot.State {
  [featureKey]: BasicConfigurationState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromGHGChecklist.featureKey]: fromGHGChecklist.reducers,
    [fromGHGSource.featureKey]: fromGHGSource.reducers,
    [fromGHGCoefficient.featureKey]: fromGHGCoefficient.reducers,
    [fromESGIndicators.featureKey]: fromESGIndicators.reducers,
    [fromAPIManagement.featureKey]: fromAPIManagement.reducers,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('carbonEmission');
export const getBasicConfigurationState = createSelector(
  getParentState,
  state => state[featureKey]
);
