import { createReducer, on } from '@ngrx/store';
import { SpaceApiActions } from '../actions';
import { Space } from '../models/space.model';

export const featureKey = 'spaceTree';

export interface State {
  data: Space[];
}

export const initialState: State = {
  data: []
};

export const reducer = createReducer(
  initialState,
  on(
    // 获取组织架构树
    SpaceApiActions.loadSpaceTreeInfoSuccess,
    (state, { data }) => {
      return {
        ...state,
        data
      };
    }
  ),
  on(
    SpaceApiActions.getSpaceChildrenSuccess,
    (state, { res }) => {
      const childSpace = res;
      const orgId = childSpace[0].orgId;
      const parentId = childSpace[0].parentId;
      state.data.forEach((item, index) => {
        if (item.orgId === orgId) {
          findParent(item, parentId, childSpace);
        }
      });
      // const childSpace = res[0].childSpace[0].childSpace;
      // const currentOrgId =  res[0].orgId;
      // const currentServiceType = res[0].childSpace[0].spaceName;
      // state.data.forEach((item, index) => {
      //   if (item.orgId === currentOrgId) {
      //     item.childSpace.forEach((item2, index2) => {
      //       if (item2.spaceName === currentServiceType) {
      //         item2.childSpace = childSpace;
      //       }
      //     });
      //   }
      // });
      return {
        ...state
      };
    }
  )
);

function findParent(item, parentId, childSpace) {
  if (item.childSpace) {
    item.childSpace.forEach(child => {
      if (child.id === parentId) {
        child.childSpace = childSpace;
        return;
      } else {
        findParent(child, parentId, childSpace);
      }
    });
  }
  return;
}
