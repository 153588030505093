import { createReducer, on } from '@ngrx/store';
import {
  DashboardApiActions,
} from '../actions';

export const featureKey = 'detail';

export interface State {
  emissionSummary: any;
  emissionRangeAnalysis: any;
  ghgEmissionPercentage: any;
  ghgEmissionTrend: any;
  ghgSourceConsumption: any;
  reductionSummary: any;
  ghgEmissionTrendByOrg: any;
}

export const initialState: State = {
  emissionSummary: null,
  emissionRangeAnalysis: null,
  ghgEmissionPercentage: null,
  ghgEmissionTrend: null,
  ghgSourceConsumption: null,
  reductionSummary: null,
  ghgEmissionTrendByOrg: null,
};

export const reducer = createReducer(
  initialState,
  on(
    DashboardApiActions.loadEmissionSummarySuccess,
    (state, { res }) => ({
      ...state,
      emissionSummary: res
    })
  ),
  on(
    DashboardApiActions.loadEmissionRangeAnalysisSuccess,
    (state, { res }) => ({
      ...state,
      emissionRangeAnalysis: res
    })
  ),
  on(
    DashboardApiActions.loadGHGEmissionPercentageSuccess,
    (state, { res }) => ({
      ...state,
      ghgEmissionPercentage: res
    })
  ),
  on(
    DashboardApiActions.loadGHGEmissionTrendSuccess,
    (state, { res }) => ({
      ...state,
      ghgEmissionTrend: res
    })
  ),
  on(
    DashboardApiActions.loadGHGSourceConsumptionSuccess,
    (state, { res }) => ({
      ...state,
      ghgSourceConsumption: res
    })
  ),
  on(
    DashboardApiActions.loadReductionSummarySuccess,
    (state, { res }) => ({
      ...state,
      reductionSummary: res
    })
  ),
  on(
    DashboardApiActions.loadGHGEmissionTrendByOrgSuccess,
    (state, { res }) => ({
      ...state,
      ghgEmissionTrendByOrg: res
    })
  ),
);