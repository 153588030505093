import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SatisfactionPageActions, SatisfactionApiActions } from '../actions';
import { Satisfaction } from '../models/satisfaction.model';

// Query
import { SatisfactionListQuery } from '../models/satisfaction-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'satisfactionList';

export interface State extends EntityState<Partial<Satisfaction>> {
  query: SatisfactionListQuery;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<Satisfaction>> = createEntityAdapter<Satisfaction>({
  selectId: (satisfaction: Satisfaction) => satisfaction.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    org_operation: null,
    org_area: null,
    org_program: null,
    businessLine: null,
    inspectionResult: null,
    dateFrom: null,
    dateTo: null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: true
});

export const reducer = createReducer(
  initialState,
  on(
    SatisfactionApiActions.loadSatisfactionList,
    (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    SatisfactionApiActions.loadSatisfactionListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount, isLoading: false };
      return adapter.addAll(res.data, newState);
    }),
  on(
    SatisfactionApiActions.loadSatisfactionListFailure,
    (state, { msg }) => ({ ...state, msg })
  ),
  on(
    SatisfactionPageActions.applyFilter,
    (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(
    SatisfactionPageActions.resetFilter,
    (state) => ({
    ...state,
    query: initialState.query
  })),
  on(
    SatisfactionPageActions.changeSort,
    (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(
    SatisfactionPageActions.changeOrderBies,
    (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(
    SatisfactionPageActions.changeCurrentPage,
    (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(
    SatisfactionPageActions.changePageSize,
    (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
);
