import { createAction, props } from '@ngrx/store';

// set isPending to true
export const setEditPageIsPendingToTrue = createAction(
  '[CO2 Historical Data Change Page] Set Edit Page isPending To True'
);

export const setEditPageIsPendingToFalse = createAction(
  '[CO2 Historical Data Change Page] Set Edit Page isPending To False'
);

export const leave = createAction(
  '[CO2 Historical Data Change Page] Leave'
);