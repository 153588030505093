import {
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';

import * as fromRoot from '@_core/reducers';
import * as fromReductionPlan from '../reduction-plan/reducers';
import * as fromReductionMethod from '../reduction-method/reducers';

export const featureKey = 'carbonReduction';

export interface CarbonReductionState {
  [fromReductionPlan.featureKey]: fromReductionPlan.State,
  [fromReductionMethod.featureKey]: fromReductionMethod.State,
}

export interface State extends fromRoot.State {
  [featureKey]: CarbonReductionState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromReductionPlan.featureKey]: fromReductionPlan.reducers,
    [fromReductionMethod.featureKey]: fromReductionMethod.reducers,
  })(state, action);
}

export const getCarbonReductionState = createFeatureSelector<State, CarbonReductionState>(
  featureKey
);
