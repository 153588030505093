import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';

import * as fromEditing from './co2-historical-data-change-edit.reducer';
import * as fromPending from './pending.reducer';

export const featureKey = 'co2HistoricalDataChange';

export interface CO2HistoricalDataChangeState {
  [fromEditing.featureKey]: fromEditing.State;
  [fromPending.featureKey]: fromPending.State;
}

export interface State {
  [featureKey]: CO2HistoricalDataChangeState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromEditing.featureKey]: fromEditing.reducer,
    [fromPending.featureKey]: fromPending.reducer,
  })(state, action);
}

export const getDataFillingState = createFeatureSelector<State>('dataFilling');
export const getCO2HistoricalDataChangeState = createSelector(
  getDataFillingState,
  state => state[featureKey]
);

// Pending
// ----------------------------------------------------------------------------

export const getIsPending = createSelector(
  getCO2HistoricalDataChangeState,
  state => state.pending
);

// CO2 Data Filling
// ----------------------------------------------------------------------------

export const getEditingCO2HistoricalDataChange = createSelector(
  getCO2HistoricalDataChangeState,
  state => state.editing
);
